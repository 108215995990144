import PlateIcon from 'assets/redLoop.svg'
import CloseIcon from '@mui/icons-material/Close';
import { defaultTheme } from "styles/theme/default";
import { Line, StyledUnavailableIcon } from "./styles";
import { Input } from "components/Input";
import { IRoute, IWaybill } from "interfaces/IWaybill";
import { useEffect, useState } from "react";
import { RouteSelect } from "./RouteSelect";
import { ILineup } from 'interfaces/ILineup';
import { dateToday } from 'utils/dateToday';
import { DistSelect } from 'components/PopupForms/DriverForm/DistSelect';
import { IDist } from 'interfaces/IDist';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { IDriver } from 'interfaces/IDriver';
import { driverVehicleService } from 'services/driverVehicleService';
import { toast } from 'react-toastify';

interface IProps {
  element: IRoute,
  lineups: ILineup[],
  saveLineup: (x: any, y?: boolean) => void,
  handleChangeDist: (x: any, y: any, z?: any) => any
  boxesByRouteCarambola: { [key: string]: number }
}

export function RouteCard({ element, saveLineup, handleChangeDist, boxesByRouteCarambola }: IProps) {
  const [open, setOpen] = useState(false)
  const [routeInput, setRoute] = useState<string | number>(element?.route)
  const [newWaybill, setWaybill] = useState<IWaybill>(element as IWaybill)
  const [filter, setFilter] = useState(false)
  const [depot, setDepot] = useState(false)
  const [isUnavailableActive, setisUnavailableActive] = useState(element.driver?.isUnavailable || false);

  const handleSetRoute = (waybill: IWaybill, remove?: boolean) => {
    setOpen(false)
    if (!remove && element.route === waybill.route) return
    setRoute(waybill.route)
    if (!remove) setWaybill(waybill)
    const newElement: ILineup = {
      waybillId: waybill.id,
      vehicleId: remove === true ? 0 : element.id,
    }
    saveLineup(newElement, true)
  }

  const handlePickTransship = (transshipPoint: IDist) => {
    handleChangeDist([element.route], transshipPoint.agileCode)
  }

  const handleUnavailableDriver = () => {
    setisUnavailableActive(!isUnavailableActive);
    const newElement: IDriver = {
      name: element.driver?.name,
      id: element.driver?.id,
      isUnavailable: !isUnavailableActive,
      agileCode: element.driver?.agileCode
    }
    driverVehicleService.updateDriver(newElement, true)
  }

  const stripStyles = element.driver?.team?.colorTeam || "transparent";

  useEffect(() => {
    if (element && !element.route) {
      setRoute("")
    }
  }, [element.route])

  return (
    <Line stripColor={stripStyles} className="bodyLine">
      <td className='driverContainer' align='left'>
        <div className="cardStrip"></div>
        <span>
          {element?.driver?.name}
        </span>
      </td>
      <td align='center'>
        <div className="iconContainer">
          <Tooltip title='Motorista Indisponível'>
            <button onClick={handleUnavailableDriver} style={{ border: 'none', cursor: 'pointer', background: 'none' }}>
              <StyledUnavailableIcon isUnavailableActive={isUnavailableActive} sx={{ fontSize: '20px' }} />
            </button>
          </Tooltip>
          <div className='spacer'></div>
          {element.isBlocked &&
            <Tooltip title='Veículo em rodízio'>
              <img src={PlateIcon} style={{ marginBottom: '4px' }} />
            </Tooltip>
          }
        </div>
      </td>
      <td align='center'>
        <div className="routeContainer">
          <Input
            type="number"
            inputMode='numeric'
            min={0}
            className="routeInput tableInput"
            value={routeInput}
            disabled={true}
            placeholder={element?.route?.toString() || ""}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
            onChange={(e) => {
              setFilter(true)
              setOpen(true)
              setRoute(e.target.value)
            }}
          />
          <button className="removeButton"
            disabled={!element.route ? true : false}
            onClick={() => {
              toast.warning("Função em desenvolvimento. Por favor, realize as alterações no controle de caixas.")
            }}>
            <CloseIcon className="removeIcon" sx={{ fill: defaultTheme.colors['gray-300'], fontSize: '17px' }} />
          </button>
          <button className="editButton"
            onClick={() => {
              toast.warning("Função em desenvolvimento. Por favor, realize as alterações no controle de caixas.")
            }}
            onBlur={() => setOpen(false)}>
            <KeyboardArrowDown className="editIcon" sx={{ fill: defaultTheme.colors['gray-300'] }} />
          </button>
          {open && <RouteSelect open={open} setOpen={setOpen} value={routeInput} filter={filter} date={dateToday()} handleSelect={handleSetRoute} />}
        </div>
      </td>
      <td align='center'>
        {element.capacity || 0}
      </td>
      <td align='center'>
        <span>{element?.boxesPlanned ? (element.boxesPlanned) : "-"}</span>
      </td>
      <td align='center'>
        <span>{boxesByRouteCarambola[String(element?.route)] || "-"}</span>
      </td>
      <td align='center'>
        <span>{(element.deliveriesFirstHalf || 0) + '/'}</span>
        <span>{element.deliveriesSecondHalf || 0}</span>
      </td>
      <td align='center'>
        <span>{element.deliveriesWeek || 0}</span>
      </td>
      <td align='center'>
        <div className='changeDepot tableSized'>
          <button type="button" className="depotBtn" disabled={!element.route} onClick={() => setDepot(true)}>
            {element.vehicle?.transshipPoint?.name || "CD Frexco"}
            <KeyboardArrowDown className="" sx={{ fill: defaultTheme.colors['gray-300'] }} />
          </button>
          <DistSelect adaptPosition={true} open={depot} setOpen={setDepot} handleSelect={handlePickTransship} />
        </div>
      </td>
    </Line>
  )
}
