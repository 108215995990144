import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const DriverSelectBtnContainer = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    float: left;
    margin-right: 12px;
    margin-left: -4px;
    margin-top: -2px;

    svg {
        transform: all 100ms;
    }

    :hover {
        svg {
            fill: ${defaultTheme.colors['base']};
        }
    }

    @media screen and (max-width:769px) {
        svg {
            transform: scale(85%);
        }
    }
`

export const ContentDriverContainer = styled.div`
  display: flex;
  align-items: center;
`

export const DriverHistoryBtnContainer = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 12px;
  margin-left: -4px;
  margin-top: -2px;

  svg {
    width: 24px;
    height: 100%;
  }

  svg path {
    fill: #000;
    transition: fill 0.2s ease-in-out;
  }

  svg:hover path {
    fill: ${defaultTheme.colors['base']};
  }

  @media screen and (max-width: 769px) {
    svg {
      transform: scale(85%);
    }
  }
`;

export const ObsBtnContainer = styled.button`
    border: none;
    background-color: transparent;
`

export const Line = styled.tr`
    width: 100%;
    height: 48px;

    .bodyLine:nth-child(2n) {
        background-color: #F5F5F5;
    }

    .editBoxesIcon{
        font-size: 18px;
        fill: ${defaultTheme.colors['gray-300']};
        cursor: pointer;
        opacity: 0;
        transition: opacity 100ms;
    }

    .editBoxesIcon:hover {
        opacity: 1;
    }

    .editReturnDateIcon {
      font-size: 20px;
      fill: ${defaultTheme.colors['gray-300']};
      cursor: pointer;
      transition: opacity 100ms;
    }

    @media screen and (max-width:769px) {
        .editBoxesIcon {
            opacity: 1;
        }
    }
`

export const HistoryButtonContainer = styled.button`
    border: none;
    background-color: transparent;
`

export const historyIconStyle = {
  color: defaultTheme.colors['gray-300'],
  fontSize: '17px',
};
