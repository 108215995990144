const PeopleTimeIcon = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="currentColor" stroke="none">
      <path d="M2252 4836 c-588 -112 -998 -624 -969 -1211 31 -628 543 -1115 1172
      -1115 263 0 495 78 707 238 319 240 500 650 458 1037 -54 509 -391 904 -879
      1032 -120 31 -371 41 -489 19z m376 -322 c247 -51 468 -220 581 -445 72 -145
      93 -233 93 -389 0 -156 -21 -244 -93 -389 -114 -227 -336 -395 -588 -446 -112
      -23 -294 -16 -401 15 -220 63 -411 218 -519 421 -266 501 20 1110 579 1235 80
      18 257 17 348 -2z"/>
      <path d="M3600 2499 c-162 -19 -362 -96 -501 -190 -81 -55 -198 -166 -260
      -248 -65 -86 -153 -264 -183 -373 -69 -241 -51 -511 48 -740 140 -324 408
      -552 763 -650 67 -18 105 -22 263 -22 170 0 193 2 285 27 117 32 258 97 359
      167 87 60 211 183 273 271 66 93 140 253 170 366 34 130 42 336 19 470 -79
      454 -437 822 -883 907 -106 20 -261 27 -353 15z m317 -334 c273 -63 494 -271
      583 -550 34 -106 39 -292 12 -406 -37 -151 -99 -266 -206 -377 -108 -113 -237
      -186 -395 -224 -114 -27 -300 -22 -406 12 -281 89 -484 305 -552 588 -23 99
      -21 275 6 377 96 367 439 620 811 599 47 -3 113 -12 147 -19z"/>
      <path d="M3650 1948 c-19 -13 -45 -43 -58 -68 -22 -44 -23 -52 -20 -285 3
      -205 6 -245 20 -272 21 -38 372 -290 424 -304 64 -18 147 20 179 81 8 16 15
      48 15 70 0 78 -22 104 -177 213 l-143 102 0 183 c0 204 -7 234 -63 274 -47 34
      -132 36 -177 6z"/>
      <path d="M2160 2313 c-235 -18 -509 -64 -725 -124 -650 -178 -1077 -517 -1155
      -915 -7 -37 -10 -145 -8 -294 3 -231 3 -236 30 -292 34 -73 103 -142 176 -176
      l57 -27 978 -3 c890 -2 981 -1 1013 14 50 24 77 68 82 133 5 70 -22 115 -88
      149 l-44 22 -931 0 c-711 0 -934 3 -943 12 -9 9 -12 69 -12 208 0 169 3 205
      19 253 83 241 429 474 898 606 248 70 508 108 792 118 207 7 246 18 289 83 30
      45 28 130 -6 177 -38 54 -73 63 -234 61 -78 -1 -163 -3 -188 -5z"/>
    </g>
  </svg>
);

export default PeopleTimeIcon;
