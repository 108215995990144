import { IBoxCount, IBoxes, IBoxReturn } from "interfaces/IBoxes";
import { api as apiService, ApiService } from "./api";
import { baseURL } from "settings";
import { ISensitiveItem } from "interfaces/ISensitiveItems";

interface IBoxesSentParams {
  page?: number;
  pageSize?: number;
  search?: string;
  notPaginated?: boolean;
  includeSupportDrivers?: boolean;
  startDeliveryDate?: string;
  endDeliveryDate?: string;
  deliveryDate?: string;
  route?: number;
  vehicleId?: number;
}

export class BoxControlService {
  constructor(
    private readonly api: ApiService,
    private readonly baseURL: string
  ) { }

  public getBoxReturn = (boxesReturnId: number) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-return`, {
      params: {
        boxesReturnId,
      },
    });
  }

  getBoxesSent = (params: IBoxesSentParams) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-sent`, { params });
  }

  public getBoxesBalance = (params: any) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-balance`, { params });
  };

  public getFirstMileTeam = () => {
    return this.api.get(`${this.baseURL}/v1/delivery/driver`, { params: { teamId: 7 } });
  };

  public updateBoxReturn = (updates: IBoxReturn[]) => {
    return this.api.put(`${this.baseURL}/v1/delivery/boxes-return`, {
      updates,
    });
  }

  public updateComment = (data: IBoxes): Promise<any> => {
    return this.api.put(`${this.baseURL}/v1/delivery/boxes-sent`, data);
  };

  public updateBoxCount = async (elements: IBoxCount[]) => {
    return this.api.put(
      `${this.baseURL}/v1/delivery/boxes-count/bulk`,
      elements
    );
  };

  public updateBrokenItems = (elements: ISensitiveItem[]) => {
    return this.api.put(
      `${this.baseURL}/v1/delivery/conference-item/bulk`,
      elements
    );
  };

  public submitForm = (element: IBoxes): Promise<any> => {
    const params = {
      route: element.route,
      vehicleId: element.vehicle.id,
      firstMile: element.firstMile,
      boxesSent: element.boxesSent,
      returnComment: element.returnComment,
      countComment: element.countComment,
      deliveryDate: element.deliveryDate,
      isSupport: element.isSupport,
    };

    return this.api.put(`${this.baseURL}/v1/delivery/boxes-sent`, params);
  };

  public createBoxReturn = (element: IBoxes) => {

    const params = {
      boxesReturned: element.boxesReturned,
      route: element.route,
      isSupport: element.isSupport,
      deliveryDate: element.deliveryDate,
      vehicleId: element.vehicle.id,
    };

    return this.api.post(`${this.baseURL}/v1/delivery/boxes-return`, params);
  }

  public saveFileBoxesReturn = (start: string, end: string) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-sent/file`, {
      responseType: "arraybuffer",
      params: {
        startDeliveryDate: start,
        endDeliveryDate: end,
      },
    });
  };

  public getOrdersByRoute = (route: number, hasConference?: boolean) => {
    const params = {
      route: route,
      page: 0,
      hasConferenceItems: hasConference,
    };

    return this.api.get(`${this.baseURL}/v1/delivery/route-tag`, { params });
  };

  public getOrdersBoxCount = (params: {
    route?: number;
    startCountDate?: string;
    endCountDate?: string;
  }) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-count`, { params });
  };

  public getSensitiveItems = (orderId: string | number) => {
    const params = {
      pageSize: 0,
      orderId: orderId,
    };

    return this.api.get(`${this.baseURL}/v1/delivery/conference-item`, {
      params,
    });
  };
}

export const boxControlService = new BoxControlService(apiService, baseURL);
