import { IBoxes } from "interfaces/IBoxes"
import { ContentDriverContainer, DriverHistoryBtnContainer, HistoryButtonContainer, Line, historyIconStyle } from "./styles"
import { ChangeEvent, useState } from "react"
import { DriverSelectBtnContainer, ObsBtnContainer } from "./styles";
import ObsIcon from 'assets/obsGray.svg'
import LoopIcon from '@mui/icons-material/Loop';
import PeopleTimeIcon from 'assets/peopleTime'
import ObsNewIcon from 'assets/obsNotification.svg'
import { IDriver } from "interfaces/IDriver"
import { IVehicle } from "interfaces/IVehicle"
import { toast } from "react-toastify"
import { routeService } from "services/routeService"
import { DriverSelect } from "components/PopupForms/DriverForm/DriverSelect"
import { HiPencilAlt } from "react-icons/hi";
import { defaultTheme } from "styles/theme/default"
import { Popup } from "components/Popup"
import { LastRouteForm } from "components/PopupForms/LastRouteForm"
import { Tooltip } from "@mui/material"
import { FaHistory } from "react-icons/fa"
import { MdEditCalendar } from "react-icons/md";
import { EditReturnDateForm } from "components/PopupForms/EditReturnDateForm"
import { IChangeDriverParams, IConfirmationPopupVisible } from "..";
import { boxControlService } from "services/boxControlService";
import { ShowDriverChangeHistory } from "components/PopupForms/ShowDriverChangeHistory";
import { set } from "react-hook-form";

interface IProps {
  element: IBoxes;
  onSubmitSent: (element: IBoxes) => Promise<void>;
  onSubmitReturn: (element: IBoxes) => Promise<void>;
  handleEdit: (x: IBoxes) => void;
  firstMileTeam: string[];
  handleEditHistory: (data: IBoxes) => void;
  updateData: (x: IBoxes, y?: boolean) => void;
  oldData: React.MutableRefObject<IBoxes[]>;
  formLoading: boolean;
  setFormLoading: (x: boolean) => void;
  confirmationPopupVisible: IConfirmationPopupVisible;
  setConfirmationPopupVisible: React.Dispatch<React.SetStateAction<IConfirmationPopupVisible>>;
  updateDriver: (params: IChangeDriverParams) => void;
}

export const FormTableLine = ({
  element,
  onSubmitSent,
  onSubmitReturn,
  handleEdit,
  handleEditHistory,
  firstMileTeam,
  updateData,
  oldData,
  formLoading,
  setFormLoading,
  setConfirmationPopupVisible,
  updateDriver,
}: IProps) => {
  const [selectVisible, setSelectVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [lastRoutePopupVisible, setLastRoutePopupVisible] = useState(false)
  const [returnControlPopupVisible, setReturnControlPopupVisible] = useState(false)
  const [driverHistoryPopupVisible, setDriverHistoryPopupVisible] = useState(false)

  const balanceColor = () => {
    const diff = (element.lastRoute?.boxesSent || 0) - (element.lastRoute?.boxesReturned || 0);
    if (!diff) return {}
    if (diff > 0) return { color: defaultTheme.colors['danger'] }
    return { color: defaultTheme.colors['base'] }
  }

  const fetchWaybill = async (driver: IDriver) => {
    try {
      const response = await routeService.getWaybill(element.route);
      if (driver.vehicles) {
        await handleDriverSelect(
          driver.vehicles[0],
          response.results[0].id,
          response.results[0].vehicle
        );
      }
    } catch (err) {
      toast.error("Não foi possível encontrar um Waybill para a rota especificada");
    }
  }

  const handleSaveBoxesSent = () => {
    const lastData = oldData.current.find((x: IBoxes) => x.id === element.id)

    if (lastData === element) return

    setFormLoading(true)
    onSubmitSent(element);
    updateData(element, true);
    setFormLoading(false)
  };

  const handleSaveBoxesReturned = async () => {
    const lastData = oldData.current.find((x: IBoxes) => x.id === element.id)

    if (lastData === element) return

    setFormLoading(true)
    onSubmitReturn(element);
    updateData(element, true);
    setFormLoading(false)
  }

  const handleBoxConfirmed = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(value)
    if (newValue < 0) {
      toast.warning("O valor não pode ser menor que 0!")
      return
    }

    const newData = { ...element, boxesReturned: newValue };
    updateData(newData);
  }

  const handleBoxSentConfirmed = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(value)
    if (newValue < 1) {
      toast.warning("O valor não pode ser menor que 1!")
      return
    }


    const newData = { ...element, boxesSent: newValue };
    updateData(newData);
  }

  const handleDriverSelect = async (
    vehicle: IVehicle,
    waybillId: number,
    changedVehicle?: IVehicle
  ) => {
    if (vehicle && vehicle.id !== undefined) {
      const params = {
        waybillId: waybillId,
        vehicleId: vehicle.id,
        changedVehicleId: changedVehicle?.id,
        action: "changeDriverInBoxesSent",
        deliveryDate: element.deliveryDate,
      };

      try {
        const existentRouteResponse = await boxControlService.getBoxesSent({
          vehicleId: vehicle.id,
          startDeliveryDate: element.deliveryDate,
          endDeliveryDate: element.deliveryDate
        })

        const existentRouteData = existentRouteResponse.results

        if (existentRouteData.length > 0) {
          const existentRoute = existentRouteData[0]

          setConfirmationPopupVisible({
            visible: true,
            route: element,
            existentRoute: existentRoute,
            onConfirm: () =>
              updateDriver(params),
          });
          return;
        }

        updateDriver(params);
      } finally {
        setLoading(false);
      }
    }
  };


  const handleSelectOption = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    setFormLoading(true)
    const option = value === "Sem seleção" ? "" : value;
    const newData = { ...element, firstMile: option };

    updateData(newData)
    onSubmitSent(newData);
    setFormLoading(false)
  };

  const handleEditLastRoute = () => {
    setLastRoutePopupVisible(true);
  }

  return (
    <>
      <Popup
        popupTitle='Última Rota'
        open={lastRoutePopupVisible}
        setOpen={setLastRoutePopupVisible}
        isEditPopup
        content={
          <LastRouteForm
            data={element}
            updateData={updateData}
            onSubmitSent={onSubmitSent}
            onSubmitReturn={onSubmitReturn}
            setOpen={setLastRoutePopupVisible}
          />
        }
        keepOnBlur
      />
      <Popup
        popupTitle="Editar Datas de Retorno"
        open={returnControlPopupVisible}
        setOpen={setReturnControlPopupVisible}
        isEditPopup
        content={
          <EditReturnDateForm
            data={element}
            setOpen={setReturnControlPopupVisible}
          />
        }
        keepOnBlur
      />
      <Popup
        popupTitle="Histórico de Alterações"
        open={driverHistoryPopupVisible}
        setOpen={setDriverHistoryPopupVisible}
        content={
          <ShowDriverChangeHistory
            data={element}
            setOpen={setDriverHistoryPopupVisible}
          />
        }
        keepOnBlur
      />
      <Line className="bodyLine">
        <td align="left" className='contentTable' style={{ paddingLeft: "2%" }}>
          <ContentDriverContainer>
            <Tooltip title="Alterar Motorista">
              <DriverSelectBtnContainer className="driverSelectBtn" onClick={() => setSelectVisible(true)} disabled={loading}>
                <LoopIcon />
              </DriverSelectBtnContainer>
            </Tooltip>
            <Tooltip title="Histórico de Alterações da Rota ">
              <DriverHistoryBtnContainer className="driverSelectBtn" onClick={() => setDriverHistoryPopupVisible(true)} disabled={loading}>
                <PeopleTimeIcon />
              </DriverHistoryBtnContainer>
            </Tooltip>
            <div style={{ flexGrow: 1, wordWrap: "break-word" }}>
              {(element?.vehicle?.driver?.name || "Sem Nome")}
            </div>
          </ContentDriverContainer>
          {selectVisible && (
            <DriverSelect
              open={selectVisible} setOpen={setSelectVisible}
              handleSelect={fetchWaybill}
              style={{
                top: 'inherit',
                left: '0',
                transform: 'translateY(20px)'
              }}
              tailPosition="top"
            />
          )}
        </td>

        <td align="center">
          <span className="routes">{element.isSupport ? 'Suporte' : element.route}</span>
        </td>
        <td align="center" className='contentTable' onClick={(e: any) => e.target?.children[0]?.focus()}>
          <div className="boxAmount">
            <input
              className="boxInput"
              name="boxCount"
              type="number"
              onChange={handleBoxConfirmed}
              onBlur={handleSaveBoxesReturned}
              value={String(element.boxesReturned)}
              disabled={formLoading}
            />
            <span>de </span>
            {editing ? (
              <input
                className="boxInput"
                name="boxSent"
                type="number"
                onChange={handleBoxSentConfirmed}
                onBlur={() => {
                  handleSaveBoxesSent();
                  setEditing(false);
                }}
                value={String(element.boxesSent)}
                autoFocus
                onFocus={(e) => e.target.select()}
                disabled={formLoading}
              />
            ) : (
              <span className="boxesSent">{element.boxesSent || element.boxesConfirmed || element.boxesSent || 'N/A'}</span>
            )}
            <HiPencilAlt className='editBoxesIcon' onClick={() => setEditing(true)} />
          </div>
        </td>
        <td align="center" className='contentTable'>
          <div className="boxAmount">
            <select
              disabled={formLoading}
              className="boxSelect"
              name="firstMile"
              onChange={handleSelectOption}
              value={element.firstMile}
            >
              {firstMileTeam && firstMileTeam.map((driver) => (
                <option key={driver} disabled={loading} value={driver}>{driver}</option>
              ))}
            </select>
          </div>
        </td>
        <td align="center" className='contentTable'>
          {element.lastRoute ?
            <div className="lastBalance" style={balanceColor()}>
              <Tooltip title="Visualizar última rota">
                <span onClick={() => handleEditLastRoute()}>
                  {element.lastRoute?.boxesReturned - element.lastRoute?.boxesSent < 0 ? '-' : ''}
                  {Math.abs(element.lastRoute?.boxesSent - element.lastRoute?.boxesReturned)}
                </span>
              </Tooltip>
            </div>
            :
            <span>-</span>
          }
        </td>
        <td align="center">
          {element.vehicle?.distributionCenter}
        </td>
        <td align="center" className='contentTable'>
          <div className="iconBtns">
            <Tooltip title="Histórico do Motorista">
              <HistoryButtonContainer onClick={() => handleEditHistory(element)}>
                <FaHistory style={historyIconStyle} />
              </HistoryButtonContainer>
            </Tooltip>
            <Tooltip title="Editar Observação">
              <ObsBtnContainer onClick={() => handleEdit(element)}>
                <img src={element.returnComment ? ObsNewIcon : ObsIcon} />
              </ObsBtnContainer>
            </Tooltip>
            <Tooltip title="Editar Data de Retorno">
              <span>
                {element.boxesReturned ? (
                  <MdEditCalendar className="editReturnDateIcon" onClick={() => setReturnControlPopupVisible(true)} />
                ) : (
                  <MdEditCalendar
                    className="editReturnDateIcon disabled"
                    onClick={() => toast.warning("Não é possível editar a data de retorno de uma rota sem caixas devolvidas")}
                  />
                )}
              </span>
            </Tooltip>
          </div>
        </td>
      </Line>
    </>
  )
}
