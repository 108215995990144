import { Button } from "components/Button";
import { ConfirmContainer } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { IBoxes } from "interfaces/IBoxes";

interface IProps {
  setPopupVisible: (state: any) => void;
  popupVisible: {
    visible: boolean;
    route: IBoxes | null;
    existentRoute: IBoxes | null;
    onConfirm: () => void;
  };
}

export function ConfirmPopup({ setPopupVisible, popupVisible }: IProps) {
  const { visible, route, existentRoute, onConfirm } = popupVisible;

  if (!visible) {
    return null;
  }

  return (
    <ConfirmContainer onClick={() => setPopupVisible({ ...popupVisible, visible: false })}>
      <div className="backdrop"></div>
      <div className="popup" onClick={(e) => e.stopPropagation()}>
        <CloseIcon
          className="closeIcon"
          onClick={() => setPopupVisible({ ...popupVisible, visible: false })}
        />
        <div className="fullContent">
          <div className="header">
            <h1 className="confirmText">
              O motorista {existentRoute?.vehicle?.driver?.name} já possui uma rota ativa.
            </h1>
            <h4 className="confirmText">
              Deseja aplicar as seguintes alterações?
            </h4>
          </div>
          <div className="bodyContent">
            <div className="content">
              <p>
                Rota {existentRoute?.route}: {existentRoute?.vehicle?.driver?.name} → {route?.vehicle?.name}
              </p>
              <p>
                Rota {route?.route}: {route?.vehicle?.driver?.name} → {existentRoute?.vehicle?.name}
              </p>
            </div>
            <div className="confirmBtnContainer">
              <Button
                className="deleteBtn"
                text="Confirmar"
                onClick={() => {
                  onConfirm();
                  setPopupVisible({ ...popupVisible, visible: false });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ConfirmContainer >
  );
}
