import styled from 'styled-components';
import { defaultTheme } from 'styles/theme/default';

export const ConfirmContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    min-height: 300px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 24px;
    z-index: 220;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    color: ${defaultTheme.colors.black};
  }

  .header {
    position: relative;

    h1 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 16px;
    }

    h4 {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 16px;
      font-size: 1rem;
      color: ${defaultTheme.colors['base-text']};
    }
  }

  .bodyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    p {
      margin: 8px 0;
      font-size: 1rem;
      color: ${defaultTheme.colors['base-text']};
    }
  }

  .confirmBtnContainer {
    margin-top: 24px;

    .deleteBtn {
      background-color: ${defaultTheme.colors.base};
      width: 100%;
      max-width: 200px;
      height: 40px;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      border: none;
      border-radius: 8px;
    }
  }

  @media screen and (max-width: 820px) {
    .popup {
      padding: 16px;
    }

    .header h1 {
      font-size: 1rem;
    }

    .content {
        p {
          font-size: 0.9rem;
        }
      }
  }`
