import styled from 'styled-components'

export const FormContainer = styled.form`
    min-width: 450px;
    max-height: 400px;
    min-height: 100px;
    z-index: 200;
    overflow-y: auto;
    background: white;
    border-radius: 12px;
    padding: 20px;
    animation: fadeIn 0.3s ease-in-out;

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: scale(0.95);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 10px;

        h2 {
            font-size: 18px;
            font-weight: bold;
            color: #333;
        }

        button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 18px;
            color: #888;
            transition: color 0.3s;

            &:hover {
                color: #333;
            }
        }
    }

    .dataCardContainer {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .listData {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            align-items: center;
            justify-content: space-between;
            padding: 15px 20px;
            background: linear-gradient(145deg, #f5f5f5, #ffffff);
            border-radius: 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            min-height: 60px;
            transition: transform 0.2s;

            &:hover {
                transform: scale(1.02);
            }

            span {
                font-size: 14px;
                color: #555;

                &:nth-child(2) {
                    font-weight: bold;
                    color: #333;
                }
            }
        }

    }

    @media (max-width: 768px) {
        min-width: 100px;
        padding: 5px;

        .header h2 {
            font-size: 16px;
        }

        .dataCardContainer .listData {
            flex-direction: column;
            padding: 12px 15px;

            span {
                font-size: 13px;
            }
        }
    }
`;

export const SelectBack = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 190;
    animation: fadeInBackground 0.3s ease-in-out;

    @keyframes fadeInBackground {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

