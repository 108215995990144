import { LinearProgress } from "@mui/material"
import { IBoxes } from "interfaces/IBoxes"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { FormContainer } from "./styles"
import { routeService } from "services/routeService"
import { IVehicle } from "interfaces/IVehicle"
import { dateFormatWithHour } from "utils/dateFormat"

interface IEditReturnDateForm {
  data: IBoxes,
  setOpen: (x: boolean) => void
}

interface IDriverChangeHistory {
  id: number
  route: string
  newVehicle: IVehicle
  oldVehicle: IVehicle
  created: string
}

export const ShowDriverChangeHistory = ({ data, setOpen }: IEditReturnDateForm) => {
  const [driverHistoryData, setDriverHistoryData] = useState<IDriverChangeHistory[]>([])
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await routeService.getDriverChangeHistory(String(data?.route))

      if (response.length === 0) {
        toast.warning("Não há histórico de troca de motoristas para esta rota")
        setOpen(false)
      }

      setDriverHistoryData(response);
    } catch (e) {
      toast.error("Houve um erro ao buscar os dados no sistema: " + e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <FormContainer>
        {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
        <div className='cardContainer'>
          <div className="dataCardContainer">
            {driverHistoryData.map((element: IDriverChangeHistory) => {
              const oldDriverName = element?.oldVehicle?.driver?.name || "Sem Nome";
              const newDriverName = element?.newVehicle?.driver?.name || "Sem Nome";
              return (
                <div className="listData" key={element.id}>
                  <span>{dateFormatWithHour(element.created)}</span>
                  <span>{oldDriverName} &rarr; {newDriverName}</span>
                </div>
              )
            })}
          </div>
        </div>
      </FormContainer>
    </div>
  )
}
